.cookie_banner {
    display: none;
    padding: 1em 0;
    width: 100%;
    justify-content: center;
    background-color: #404245;
    margin-bottom: 1em;
}

.cookie_banner_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1000px;
}

.cookie_banner_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1em 2em;
}

.cookie_banner_block_text {
    margin-top: 0px;
}

.cookie_banner_block_accept {
    justify-content: flex-end;
    margin-bottom: 0px;
}

.cookie_banner_text {
    font-family: "Work Sans", sans-serif;
    color: #fff;
}

.cookie_banner_text a {
    text-decoration: none;
    color: hsl(210, 74%, 40%);
}

.cookie_banner_x svg {
    fill: white;
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.cookie_accept_btn {
    font-family: "chalk";
    font-size: 2em;
    background-color: hsl(210, 74%, 40%);
    color: white;
    border: none;
    width: 130px;
    height: 40px;
    cursor: pointer;
}